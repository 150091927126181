import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import showMktoForm from "../../helpers/showMktoForm";
import Button from "../../components/custom-widgets/button";

const AcquisitionFinancing = () => {
  const title = "Acquisition Financing";
  const description =
    "Need business acquisition financing? Discover WaFd Bank as we will work with you to determine the debt capacity of the business and capital structure options.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/commercial-banking/acquisition-financing/hero-acquisition-financing-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/acquisition-financing"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-acquisition-financing-02-250.jpg"
      }
    ]
  };
  const heroData = {
    id: "acquisition-financing-hero",
    ...getHeroImgVariables(imgData, ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"]),
    altText: "Team of businesspeople sitting together at a meeting in the office.",
    chevronBgClass: "bg-dark-green-01",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Financing Your Business Goals"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => doShowMktoForm(),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: "active",
      title: "Acquisition Financing"
    }
  ];

  const doShowMktoForm = () => {
    showMktoForm(1067);
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Acquisition Financing</h1>

        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../images/thumbnail-commercial-lines-of-credit-022824.jpg"
              alt="A smiling businessman shaking hands with a businesswoman."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-green-60">
              An experienced commercial banker can play a key role in buying or selling a business. Business acquisition
              financing is customized based on the needs and financial characteristics of your company and your
              long-term goals.
            </h3>
            <ul>
              <li>
                We will work with you to determine the debt capacity of the business and capital structure options
              </li>
              <li>
                Transaction types include family ownership transitions, strategic buyers, private equity and Employee
                Stock Ownership Plans (ESOP)
              </li>
            </ul>
            <Button
              id={"cta-contact-us"}
              class={"d-none d-lg-inline-block btn-primary no-min-width px-3"}
              onClick={() => doShowMktoForm()}
              showIcon={false}
              type={"button"}
              text={"Contact Us"}
            />
            <Button
              id={"cta-contact-us-mobile"}
              class={"d-block d-lg-none btn-primary w-100"}
              onClick={() => doShowMktoForm()}
              showIcon={false}
              type={"button"}
              text={"Contact Us"}
            />
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
    </SecondaryLanding>
  );
};
export default AcquisitionFinancing;
